.container {
  width: 50vw;
  height: 50vw;
  margin: 40px auto;
  border: 1px solid #48a928;
  display: grid;
  grid-template-columns: 120px;
  grid-template-rows: 120px;
  place-content: center center;
  border-radius: 50%;
  position: relative;
}
.container2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30vw;
  height: 30vw;
  border: 1px solid #48a928;
  display: grid;
  grid-template-columns: 95px;
  grid-template-rows: 95px;
  place-content: center center;
  border-radius: 50%;
}
.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  padding: 20px;
}
.container3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12.5vw;
  height: 12.5vw;
  border: 1px solid #48a928;
  display: grid;
  grid-template-columns: 80px;
  grid-template-rows: 80px;
  place-content: center center;
  border-radius: 50%;
}
.item {
  position: relative;
  grid-area: 1/1;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  animation: spin 48s var(--d, 0s) linear infinite;
  transform: rotate(0) translate(25vw) rotate(0);
}
.toolTip{
    display: none;
    position: absolute;
    top:0%;
    left: -100%;
    width: 100px;
    height: 50px;
    background-color: red;
    z-index: 10;
}
.item:hover .toolTip{
    display: block;
}

.item2 {
  position: relative;
  grid-area: 1/1;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  animation: spin2 36s var(--d, 0s) linear infinite;
  transform: rotate(0) translate(15vw) rotate(0);
}

.item3 {
  position: relative;
  grid-area: 1/1;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  animation: spin3 24s var(--d, 0s) linear infinite;
  transform: rotate(0) translate(6.25vw) rotate(0);
}

@keyframes spin {
  100% {
    transform: rotate(360deg) translate(25vw) rotate(-360deg);
  }
}
@keyframes spin2 {
  100% {
    transform: rotate(-360deg) translate(15vw) rotate(360deg);
  }
}
@keyframes spin3 {
  100% {
    transform: rotate(360deg) translate(6.25vw) rotate(-360deg);
  }
}

@media screen and (max-width: 968px) {
    .container{
        width: 80vw;
        height: 80vw;
    }
    .item{
        transform: rotate(0deg) translate(40vw) rotate(0);
    }
    @keyframes spin{
        100% {
            transform: rotate(360deg) translate(40vw) rotate(-360deg);
        }
    }
    .container2{
        width: 50vw;
        height: 50vw;
    }
    .item2{
        transform: rotate(0deg) translate(25vw) rotate(0);
    }
    @keyframes spin2{
        100% {
            transform: rotate(-360deg) translate(25vw) rotate(360deg);
        }
    }
    .container3{
        width: 25vw;
        height: 25vw;
    }
     
    .item3{
        transform: rotate(0deg) translate(12.5vw) rotate(0);
    }
    @keyframes spin3{
        100% {
            transform: rotate(360deg) translate(12.5vw) rotate(-360deg);
        }
    }
    
}
@media screen and (max-width: 680px) {
    .container{
        grid-template-columns: 80px;
        grid-template-rows: 80px;
  
    }
    .container2{
        grid-template-columns: 70px;
        grid-template-rows: 70px;
  
    }
    .container3{
        grid-template-columns: 60px;
        grid-template-rows: 60px;
  
    }
}
