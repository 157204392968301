.verticaltabs {
  /* height: 750px; */
  padding: 0px 10px;
  justify-content: flex-start;
}

.leftTabContainter {
  width: 33.3%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.rightTabContainer {
  width: 33.3%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contentContainer {
  width: 33.3%;
  border: 1px solid #48a928 !important;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  @apply transition duration-200 ease-linear;
}
.rightTabs .inner {
  list-style: none;
  font-size: 24px;
  color: #7a7a7a;
  border: 1px solid #7a7a7a;
  height: 125px;
  margin-top: 40px;
  border-radius: 6px;
  display: flex;
  flex-direction: row-reverse;
  padding: 0px 10px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}
.rightTabs .inner:hover {
  color: #000000 !important;
  border: 1px solid #48a928 !important;
  cursor: pointer;
  border-radius: 6px;
}
.leftTabs .inner:hover {
  color: #000000 !important;
  border: 1px solid #48a928 !important;
  cursor: pointer;
  border-radius: 6px;
}
.leftTabs .inner{
  list-style: none;
  font-size: 24px;
  color: #7a7a7a;
  border: 1px solid #7a7a7a;
  cursor: pointer;
  height: 125px;
  margin-top: 40px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
  align-items: center;
  justify-content: space-between;
}

.tabs div:hover,
.active {
  color: #000000 !important;
  border: 1px solid #48a928 !important;
  cursor: pointer;
  font-size: 16px;
  border-radius: 6px;
  font-weight: 500;
}

.selectedTab {
  color: #48a928 !important;
}
