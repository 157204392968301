.accordion {
  @apply p-0 ml-0;
}
.accordionTab {
  @apply flex flex-col justify-between items-start border-b-[0.4px] border-black/10;
}
.accordionTabButton {
  @apply py-5 px-0 flex flex-row;
  justify-content: space-between;
  margin-bottom: 0 !important;
  width: 100%;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.accordionTabHeader {
  @apply text-h4 font-semibold p-0 m-0 inline cursor-pointer;
  @apply transition-all duration-[0.5s] ease-in-out;
}
.accordionTabHeader:hover {
  @apply text-primary duration-[0s];
}
.accordionTab.active .accordionTabHeader {
  @apply pb-[5px] mb-0 ;
  @apply transition-all duration-[0.5s] ease-in-out;

}

.accordionTabTextContent {
  @apply mt-0 mb-0 h-0 overflow-hidden hidden text-base;
  @apply transition-all duration-[0.5s] ease-in;
}

.accordionTab.active .accordionTabTextContent {
  @apply block h-full;
}
.accordionTab.active .accordionTabHeader {
  @apply text-primary;
}
.arrow_active {
  transform: rotate(-90deg);
  stroke: theme('colors.primary');
  transition: transform 0.5s ease-in-out;
}
.accordionImageContainer {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  }
.accordionImage {
  display: none;
  transform: translateY(-100%);
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.accordionImage.currentImage {
  transform: translateY(0%);
  transform: translateX(0%);
  animation: fromLeftIn .8s ease-in-out;

}

.accordionHolder{
  transform: translateX(0%);
  animation: fromLeftIn .8s ease-in-out;
}
@keyframes fromLeftIn {
  0%{
    transform: translateX(-100%);
  }
  100%{
    transform: translateX(0);
  }
}

